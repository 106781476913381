<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col xl="6" md="6">
        <bruna-element-loader :dataUrl="chart1">
        </bruna-element-loader>
      </b-col>
      <b-col xl="6" md="6">
        <bruna-element-loader :dataUrl="chart2">
        </bruna-element-loader>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'


export default {
  data() {
    return {
      itemsData: [],
      chart1: useApiServices.AnalisisRentabilidadChart1,
      chart2: useApiServices.AnalisisRentabilidadChart2,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaElementLoader
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
    },

    updateCards() {
      axios.get(useApiServices.AnalisisRentabilidadStatistics)
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    }
  },
};
</script>
  
<style scoped></style>